import React, { useState, useEffect, useMemo, useCallback } from "react";
import Head from "next/head";
import { NextComponentType, NextPage, NextPageContext } from "next";
import { default as Router, Router as RouterType } from "next/router";
import { Global } from "@emotion/core";
import { ThemeProvider } from "emotion-theming";
import { fetchQuery, GraphQLTaggedNode, ReactRelayContext } from "react-relay";
import { hotjar } from "react-hotjar";
import mixpanel from "mixpanel-browser";

import "swiper/swiper.min.css";

import { createRelayEnvironment } from "@relay/createRelayEnvironment";
import { MessageType, FeedbackContext } from "@contexts/FeedbackContext";
import {
  ConfigVariables,
  ConfigVariablesContext,
  initialConfigVariablesValue,
} from "@contexts/ConfigVariables";
import { UserContext } from "@contexts/UserContext";
import { VariablesContext } from "@contexts/VariablesContext";

import { getSsrToken } from "@lib/getSsrToken";
import { getFlashFromCtx } from "@lib/getFlashFromCtx";
import { getConfigVariablesFromCtx } from "@lib/getConfigVariables";
import { getQueryValue } from "@lib/getQueryValue";

import TwoFactorModal from "@components/common/TwoFactor/TwoFactorModal";

import { theme, GlobalStyle, Theme } from "../styles/theme";
import { PageComponent, PageProps } from "../types";

export interface User {
  _id: string;
  id: string;
  name: string;
  email: string;
  picture: string;
  company: string;
  onboarding: number;
  roles: string[];
  updateUser: (user: any) => void;
  authMethod: string;
  isTrial: boolean;
  two_factor_enabled: boolean;
  ask_two_factor: Date;
  skippedTwoFA: boolean;
  gotInstructionsTwoFA: boolean;
}

interface Props {
  user: User;
  two_factor_validated: boolean;
  pageProps: PageProps;
  variables?: Record<string, unknown>;
  Component: {
    query: GraphQLTaggedNode;
  } & NextComponentType<NextPageContext, any, PageProps>;
  endpoints: { graphql: string; websocket: string };
  relayRecords?: Record<string, Record<string, unknown>>;
  ssrToken?: string;
  flashProps?: MessageType;
  configVariablesProps: ConfigVariables;
  isPublicComponent: boolean;
  isOnboarding?: boolean;
}

export type PageContext = NextPageContext & {
  req: { user?: User; ssrToken?: string };
  res: {
    flash: { type: "ok" | "error"; msg: string };
    two_factor_validated: boolean;
    clientConfigVariables: ConfigVariables;
  };
};

interface Context extends NextPageContext {
  router: RouterType;
  Component: PageComponent;
  ctx: PageContext;
}

const fetchMe = async () => {
  try {
    const response = await fetch("/me");
    return await response.json();
  } catch (ex) {
    return false;
  }
};

const fetchTwoFactorValidation = async () => {
  try {
    const response = await fetch("/two-factor-validation");
    return await response.json();
  } catch (ex) {
    return false;
  }
};

async function updateUserSession(user: Props["user"]) {
  try {
    const response = await fetch("/update-user", {
      method: "POST",
      body: JSON.stringify(user),
      headers: { "Content-type": "application/json" },
    });

    return await response.json();
  } catch (err) {
    return false;
  }
}

function randomId() {
  return `_${Math.random().toString(36).substr(2, 9)}`;
}

const MyApp: NextPage<Props> = ({
  user: userProp,
  two_factor_validated,
  pageProps = {},
  variables = {},
  Component: BaseComponent,
  endpoints,
  relayRecords,
  ssrToken,
  flashProps,
  configVariablesProps,
  isPublicComponent,
  isOnboarding,
}) => {
  const [user, setUser] = useState<User>(userProp);
  const [feedback, setFeedback] = useState<MessageType[]>(
    flashProps ? [flashProps] : [],
  );
  const [configVariables, setConfigVariables] = useState<ConfigVariables>({
    ...initialConfigVariablesValue,
    ...configVariablesProps,
  });
  const [showTwoFactorModal, setShowTwoFactorModal] = useState(true);

  const addFeedback = (
    newFeedback: Omit<MessageType, "id"> & { id?: string },
  ) => {
    const id = newFeedback.id || randomId();
    setFeedback([...feedback, { ...newFeedback, id }]);
    return id;
  };

  const removeFeedback = (id?: string) => {
    if (id) {
      setFeedback((i) => i.filter((item) => item.id !== id));
    }
  };

  const Component = useMemo(() => BaseComponent, [pageProps]);

  const updateUser = useCallback(
    async (updatedUser: Partial<User>) => {
      setUser({ ...user, ...updatedUser });

      await updateUserSession({ ...user, ...updatedUser });
    },
    [user, configVariables],
  );

  useEffect(() => {
    setUser({ ...user, ...userProp });
  }, [userProp]);

  useEffect(() => {
    const environment = configVariables.CODA_ENV;

    if (process.env.NODE_ENV === "production" && environment === "PROD") {
      if (configVariables.MIXPANEL_TOKEN) {
        mixpanel.init(configVariables.MIXPANEL_TOKEN, {
          loaded: (mixpanel) => {
            if (user?.id) {
              mixpanel.identify(user.id);
              mixpanel.people.set_once("Name", user.name);
              mixpanel.people.set_once("Email", user.email);
            }
            setConfigVariables({
              ...configVariables,
              Mixpanel: mixpanel,
            });
          },
        });
      }

      const { HOTJAR_ID: hotjarId, HOTJAR_VERSION: hotjarVersion } =
        configVariables;

      if (hotjarId && hotjarVersion) {
        hotjar.initialize(hotjarId, hotjarVersion);
      }
    }
  }, []);

  let ENV_TITLE = "";
  if (process.env.NODE_ENV === "test") {
    ENV_TITLE = "[QA]";
  } else if (process.env.NODE_ENV === "development") {
    ENV_TITLE = "[DEV]";
  }

  const environment = createRelayEnvironment({
    endpoints,
    relayRecords,
    ssrToken,
    redirect: Router.push,
    isK8: configVariables.K8,
  });

  let ask2FAExpired = !user?.two_factor_enabled;
  if (user?.ask_two_factor !== null) {
    const ask2FADate = new Date(user?.ask_two_factor);
    ask2FAExpired = ask2FADate?.toDateString() === new Date().toDateString();
  }

  const show2FA =
    user &&
    user.email?.indexOf("@accenture.com") === -1 &&
    !isPublicComponent &&
    ask2FAExpired &&
    !two_factor_validated &&
    !user.skippedTwoFA &&
    !isOnboarding;

  return (
    <VariablesContext.Provider value={{ variables }}>
      <ConfigVariablesContext.Provider value={configVariables}>
        <ReactRelayContext.Provider
          value={{
            environment,
            // @ts-ignore
            variables,
          }}
        >
          <UserContext.Provider value={{ ...user, updateUser }}>
            <FeedbackContext.Provider
              value={{ feedback, setFeedback: addFeedback, removeFeedback }}
            >
              <ThemeProvider theme={theme}>
                <Global<Theme> styles={GlobalStyle} />
                <Head>
                  <title>{ENV_TITLE} Coda</title>
                </Head>
                <Component {...pageProps} />

                {show2FA && (
                  <TwoFactorModal
                    show={showTwoFactorModal}
                    setShow={setShowTwoFactorModal}
                    onClose={() => setShowTwoFactorModal(false)}
                  />
                )}
              </ThemeProvider>
            </FeedbackContext.Provider>
          </UserContext.Provider>
        </ReactRelayContext.Provider>
      </ConfigVariablesContext.Provider>
    </VariablesContext.Provider>
  );
};

const getUser = async (ctx: PageContext) => {
  return process.browser ? await fetchMe() : ctx.req.user;
};

const getTwoFactorValidation = async (ctx: PageContext) => {
  return process.browser
    ? await fetchTwoFactorValidation()
    : ctx?.res?.two_factor_validated;
};

const getInitialProps = async ({
  Component,
  ctx,
  router,
}: Context): Promise<Omit<Props, "Component"> | null> => {
  const user = await getUser(ctx);
  const two_factor_validated = await getTwoFactorValidation(ctx);

  const configVariables = getConfigVariablesFromCtx(ctx);
  const flash = getFlashFromCtx(ctx);

  const redirect = (Location: string) =>
    process.browser
      ? router.push(Location)
      : (ctx?.res?.writeHead(302, { Location }), ctx?.res?.end());

  if (!user && !Component.public) {
    redirect("/login");
    return null;
  }

  // FIXME: remove when new issues page is totally ready
  if (ctx.pathname === "/issues/[instance_id]/[team_id]/[old_scan_id]") {
    const instanceId = getQueryValue(ctx.query.instance_id);
    redirect(`/${instanceId}/issues`);
  }

  const { HTTPS } = configVariables;
  const HOST = process.browser ? window.location.host : ctx.req.headers.host;
  const endpoints = {
    websocket: `${HTTPS ? "wss" : "ws"}://${HOST}/graphql`,
    graphql: `${HTTPS ? "https" : "http"}://${HOST}/graphql`,
  };

  if (!Component.getInitialProps) {
    return {
      user,
      two_factor_validated,
      pageProps: {},
      endpoints,
      configVariablesProps: configVariables,
      isPublicComponent: Component.public || false,
      isOnboarding: ctx.pathname.indexOf("/onboarding") !== -1,
    };
  }

  const ssrToken = getSsrToken(ctx, process.env.SSR_SECRET);
  const initialProps: PageProps = await Component.getInitialProps(ctx);

  if (!Component.query || (!process.browser && !ssrToken)) {
    return {
      flashProps: flash,
      user,
      two_factor_validated,
      endpoints,
      pageProps: { ...initialProps },
      configVariablesProps: configVariables,
      isPublicComponent: Component.public || false,
      isOnboarding: ctx.pathname.indexOf("/onboarding") !== -1,
    };
  }

  // Return of the Component.getInitialProps();
  const relayEnvironment = createRelayEnvironment({
    redirect,
    endpoints,
    ssrToken: ssrToken as string,
    isK8: configVariables.K8,
  });

  const { variables = {} } = initialProps;

  try {
    const data = await fetchQuery(relayEnvironment, Component.query, variables);

    const relayRecords = relayEnvironment.getStore().getSource().toJSON();

    return {
      flashProps: flash,
      user,
      two_factor_validated,
      endpoints,
      variables,
      relayRecords,
      ssrToken: ssrToken as string,
      pageProps: { ...initialProps, data },
      configVariablesProps: configVariables,
      isPublicComponent: Component.public || false,
      isOnboarding: ctx.pathname.indexOf("/onboarding") !== -1,
    };
  } catch (e) {
    console.error("\n\nRelay fetch error App.getInitialProps", e);
    console.error({ variables, ssrToken });
    return {
      flashProps: flash,
      user,
      two_factor_validated,
      endpoints,
      variables,
      ssrToken: ssrToken as string,
      pageProps: { ...initialProps },
      configVariablesProps: configVariables,
      isPublicComponent: Component.public || false,
      isOnboarding: ctx.pathname.indexOf("/onboarding") !== -1,
    };
  }
};

// @ts-ignore
MyApp.getInitialProps = getInitialProps;

export default MyApp;
